<template>
    <div class="tab-pane" id="nav-account" role="tabpanel" style="padding: 0 20px;">
        <div class="col-lg-9">
            <br><br>
            <!-- Start Breadcrumb Area  -->
            <div class="axil-breadcrumb-area">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-4 text-right" v-if="!userImg">
                            <img  class="img-circle" src="https://rc1.mainbitpreproduccion.shop/vendor/mainbit/footer/icons/logo_footer.png" alt="Profile">
                        </div>
                        <div class="col-4 text-right" v-else>
                            <img  class="img-circle" :src="'https://rc1.mainbitpreproduccion.shop/storage/'+userImg" alt="Profile">
                        </div>
                        <div class="col-8 text-left">
                            <h3 class="text-very-large">{{ name }}</h3>
                        </div>
                    </div>
                    <div class="row">
                        <br>
                        <div class="text-left col-4 text-large"><b>{{ totalPoints }} puntos</b></div>
                    </div>
                </div>
            </div>
            <div class="axil-dashboard-account">
                <div class="account-details-form">
                    <div class="row text-black">
                        <router-link :to="{ name: 'My Profile' }" style="text-decoration-line: none;color: #000;">
                            <div class="col-lg-6" style="margin-top: 10px;text-align: left;"> 
                                <div class="row">
                                    <div class="col-2">
                                        <i class="far fa-id-card" style="font-size: 25px;color: #0a53be;"></i>
                                    </div>
                                    <div class="col-8 text-left text-small">
                                        <b><h3 class="text-very-large">Información personal</h3></b>
                                        Información personal de tu cuenta.</div>
                                    <div class="col-2">
                                        <i class="far fa-angle-right" style="font-size: 15px;color: #0a53be;"></i>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                        <hr>
                        <router-link :to="{ name: 'Orders' }" style="text-decoration-line: none;color: #000;">
                            <div class="col-lg-6" style="margin-top: 10px;text-align: left;"> 
                                <div class="row">
                                    <div class="col-2">
                                        <i class="far fa-archive" style="font-size: 25px;color: #0a53be;"></i>
                                    </div>
                                    <div class="col-8 text-left text-small" style="font-size: 14px;">
                                        <b><h3 class="text-very-large">Pedidos</h3></b>
                                        Pedidos generados en tu cuenta.</div>
                                    <div class="col-2">
                                        <i class="far fa-angle-right" style="font-size: 15px;color: #0a53be;"></i>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                        <hr>
                        <router-link :to="{ name: 'WishList' }" style="text-decoration-line: none;color: #000;">
                            <div class="col-lg-6" style="margin-top: 10px;text-align: left;"> 
                            <div class="row">
                                <div class="col-2">
                                    <i class="far fa-heart" style="font-size: 25px;color: #0a53be;"></i>
                                </div>
                                <div class="col-8 text-left text-small" style="font-size: 14px;">
                                    <b><h3 class="text-very-large">Wishlist</h3></b>
                                    Tus productos favoritos.</div>
                                <div class="col-2">
                                    <i class="far fa-angle-right" style="font-size: 15px;color: #0a53be;"></i>
                                </div>
                            </div>
                        </div>
                        </router-link>
                        <hr>
                        <router-link :to="{ name: 'My Reviews' }" style="text-decoration-line: none;color: #000;">
                            <div class="col-lg-6" style="margin-top: 10px;text-align: left;"> 
                                <div class="row">
                                    <div class="col-2">
                                        <i class="far fa-comment" style="font-size: 25px;color: #0a53be;"></i>
                                    </div>
                                    <div class="col-8 text-left text-small" style="font-size: 14px;">
                                        <b><h3 class="text-very-large">Reseñas</h3></b>
                                        Reseñas de productos adquiridos.</div>
                                    <div class="col-2">
                                        <i class="far fa-angle-right" style="font-size: 15px;color: #0a53be;"></i>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                        <hr>
                        <router-link :to="{ name: 'Addresses' }" style="text-decoration-line: none;color: #000;">
                            <div class="col-lg-6" style="margin-top: 10px;text-align: left;"> 
                                <div class="row">
                                    <div class="col-2">
                                        <i class="far fa-map-marked-alt" style="font-size: 25px;color: #0a53be;"></i>
                                    </div>
                                    <div class="col-8 text-left text-small" style="font-size: 14px;">
                                        <b><h3 class="text-very-large">Direcciones</h3></b>
                                        Direcciones guardadas en tu cuenta.</div>
                                    <div class="col-2">
                                        <i class="far fa-angle-right" style="font-size: 15px;color: #0a53be;"></i>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                        <hr>
                        <router-link :to="{ name: 'Tickets' }" style="text-decoration-line: none;color: #000;">
                            <div class="col-lg-6" style="margin-top: 10px;text-align: left;"> 
                                <div class="row">
                                    <div class="col-2">
                                        <i class="far fa-question" style="font-size: 25px;color: #0a53be;"></i>
                                    </div>
                                    <div class="col-8 text-left text-small" style="font-size: 14px;">
                                        <b><h3 class="text-very-large">Tickets</h3></b>
                                        Tickets de ayuda generados en tu cuenta.</div>
                                    <div class="col-2">
                                        <i class="far fa-angle-right" style="font-size: 15px;color: #0a53be;"></i>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                        <hr>
                        <router-link :to="{ name: 'Rewards' }" style="text-decoration-line: none;color: #000;">
                            <div class="col-lg-6" style="margin-top: 10px;text-align: left;"> 
                                <div class="row">
                                    <div class="col-2">
                                        <i class="fa fa-trophy" style="font-size: 25px;color: #0a53be;"></i>
                                    </div>
                                    <div class="col-8 text-left text-small" style="font-size: 14px;">
                                        <b><h3 class="text-very-large">Puntos de lealtad</h3></b>
                                        Puntos que tienes disponibles para utilizar en Mainbit.shop.</div>
                                    <div class="col-2">
                                        <i class="far fa-angle-right" style="font-size: 15px;color: #0a53be;"></i>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
            <!--div class="axil-breadcrumb-area">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-12 col-md-8">
                            <div class="inner">
                                <ul class="axil-breadcrumb">
                                    <li class="axil-breadcrumb-item"><router-link :to="{ name: 'Index' }">INICIO</router-link>
                                    </li>
                                    <li class="separator"></li>
                                    <li class="axil-breadcrumb-item active" aria-current="page">Mi Perfil</li>
                                </ul>
                                <ul class="axil-breadcrumb">
                                    <li class="axil-breadcrumb-item"><router-link :to="{ name: 'Orders' }">Pedidos</router-link>
                                    </li>
                                    <li class="separator"></li>
                                    <li class="axil-breadcrumb-item"><router-link :to="{ name: 'WishList' }">WishList</router-link>
                                    </li>
                                    <li class="separator"></li>
                                    <li class="axil-breadcrumb-item"><router-link :to="{ name: 'My Reviews' }">Reseñas</router-link>
                                    </li>
                                    <li class="separator"></li>
                                    <li class="axil-breadcrumb-item"><router-link :to="{ name: 'My Profile' }">Perfil</router-link>
                                    </li>
                                </ul>
                                <ul class="axil-breadcrumb">
                                    <li class="axil-breadcrumb-item"><router-link :to="{ name: 'Addresses' }">Direcciones</router-link>
                                    </li>
                                    <li class="separator"></li>
                                    <li class="axil-breadcrumb-item"><router-link :to="{ name: 'Tickets' }">Tickets</router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div-->
            <!-- End Breadcrumb Area  -->
            <div>
                <ul v-if="isLoading">
                    <div>
                        <b-spinner variant="danger" class="position-loader" type="grow"
                            label="Cargando..."></b-spinner>
                    </div>
                </ul>
            </div>
            <!--div class="axil-dashboard-account">
                <div class="account-details-form" v-for="(row, key) in data" :key="key">
                    <div class="row">
                        <div class="col-lg-6" style="margin-top: 10px">
                            <div class="row text-left">
                                <div class="col-10">
                                    <h3>Datos personales</h3><br>
                                </div>
                                <div class="col-2">
                                    <span style="cursor: pointer;" @click="edit1()" class="cart-close sidebar-close"><i class="fas fa-edit fa-2x"></i></span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Nombre</label>
                                <input v-if="isDisabled" :value="row.first_name + ' ' + row.last_name" :disabled="isDisabled" type="text" class="form-control">
                                <input v-else v-model="row.first_name" @input="updateValueFirstName" :disabled="isDisabled" type="text" class="form-control">
                            </div>
                            <div class="form-group" v-if="!btn1Disabled">
                                <label>Apellidos</label>
                                <input v-model="row.last_name" @input="updateValueLastName"  type="text" class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label>Genero</label>
                                <select v-model="row.gender" id="options" @input="updateValueGender" :disabled="isDisabled">
                                    <option disabled value="">Please select one</option>
                                    <option v-for="option in options" :key="option.value" :value="option.value">
                                    {{ option.text }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label>Fecha de nacimiento</label>
                                <input :disabled="isDisabled" v-model="row.date_of_birth" @input="updateValueDateOfBirth" type="date" class="form-control">
                            </div>
                        </div>
                        <b-button @click="updateCustomer()" v-if="!btn1Disabled" style="margin: auto;width: 40%;top: -16px;" variant="danger">Guardar</b-button>
                        <b-button @click="cancel()" v-if="!btn1Disabled" style="margin: auto;width: 40%;top: -16px;" variant="info">Cancelar</b-button>
                    </div>
                    <h3>Datos de contacto</h3><br>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label>Correo electrónico</label>
                                <input type="text" class="form-control" :value="email" :disabled="isDisabled2">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group"><br>
                                <label>Teléfono guardado</label>
                                <span v-if="row.is_verified_phone === 0" style="color: red;">No verificado</span>
                                <span v-if="row.is_verified_phone === 1" style="color: green;">Verificado</span>
                                <input type="text" class="form-control" :value="row.phone" :disabled="isDisabled2">
                            </div>
                        </div>
                    </div>
                </div>
            </div-->
        </div>
    </div>
</template>
<script>
import store from '@/store'
import { mapState } from "vuex";
import { useToast } from "vue-toastification";
import {useRouter} from "vue-router";
import axiosClient from '@/axios';

// import { useLogto } from '@logto/vue';
export default {
    name: 'UserProfile',
    data() {
        return {
            toast: useToast(),
            row:{
                first_name:'',
                last_name:'',
                gender:'',
                date_of_birth:'',
            },
            options: [  
                { value: 'H', text: 'Hombre' },
                { value: 'M', text: 'Mujer' },
                { value: 'N', text: 'Prefiero no decir' },
            ],
            data: [],
            showModal: false,
            isDisabled: false,
            isDisabled2: false,
            showBtn1: false,
            router: useRouter(),
            userImg:'',
            name: '',
            email: '',
            tkn: store.state.user.token,
            totalPoints: 0
        }
    },
    mounted(){
        if (store.state.user.token){
            this.getProfile();
        }
        this.toggleDisabled();
        this.toggleDisabled2();
        this.btn1Disabled();
        this.email = store.state.email;
        this.getRewards();
    },
    computed: {
        ...mapState(['isLoading'])
    },
    methods: {
        testCkeck(){
            // console.log(this.logto.getOrganizationToken())
            // console.log(this.logto.getOrganizationTokenClaims())
            // console.log(this.logto.getIdTokenClaims())
            axiosClient.post('http://mb003dev.com.br/api/v1/checkout')
            .then((res) => {
                console.log(res)
                if(res.data.shopRoute){
                    window.location.href = res.data.shopRoute
                }
            })
            .catch(error => console.error('Error:', error));
        },
        updateValueFirstName(event) {
            this.row.first_name = event.target.value;
        },
        updateValueLastName(event) {
            this.row.last_name = event.target.value;
        },
        updateValueGender(event) {
            this.row.gender = event.target.value;
        },
        updateValueDateOfBirth(event) {
            this.row.date_of_birth = event.target.value;
        },
        getProfile() {  
            this.isLoading = true;
            store.dispatch('getUser')
            .then((res) => {
                this.data.push(res.data.userDetails);
                if(res.data.userDetails.is_verified === 0){
                    this.router.push({ name: 'Sign Up' });
                }
                this.userImg = res.data.userDetails.image;
                this.name = res.data.userDetails.first_name + ' ' + res.data.userDetails.last_name;
                store.state.name = res.data.userDetails.first_name;
                this.formData();
            })
            .catch(error => {
                console.error(error);
            });
        },
        getRewards() {  
            this.isLoading = true;
            store.dispatch('getRewards')
            .then((res) => {
                let totalRewardPoints = 0;
                res.data.payload.rewards.forEach((result) => {
                    if(result.status === 'Ganados'){
                       totalRewardPoints += result.reward_points; 
                    } 
                })

                this.totalPoints = totalRewardPoints
            })
            .catch(error => {
                console.error(error);
            });
        },
        cancel() {
            this.isDisabled = !this.isDisabled;
            this.btn1Disabled = true;
        },
        updateCustomer() { 
            this.isDisabled = !this.isDisabled;
            this.btn1Disabled = true;
            this.isLoading = true;
            store.dispatch('updateCustomer', {
                first_name: this.row.first_name,
                last_name: this.row.last_name,
                gender: this.row.gender,
                date_of_birth: this.row.date_of_birth,
            })
            .then((res) => {
                store.state.name = this.row.first_name;
                this.toast.success(res.data.messageForUser)
            })
            .catch(error => {
                console.error(error);
            }); 
        },
        edit1() { 
            this.isDisabled = false;
            this.btn1Disabled = false;
        },
        toggleDisabled() {
            this.isDisabled = !this.isDisabled;
        },
        toggleDisabled2() {
            this.isDisabled2 = !this.isDisabled2;
        },
        btn1Disabled() {
            this.showBtn1 = !this.showBtn1;
        },
        formData(){
            this.row.first_name = this.data[0].first_name;
            this.row.last_name = this.data[0].last_name;
            this.row.gender = this.data[0].gender;
            this.row.date_of_birth = this.data[0].date_of_birth;
        }
    },
    created() {
        // this.logto = useLogto();
    },
}
</script>
