<template>
  <router-view/>
</template>
<script>
  import { provide } from 'vue';
  import { useAuth } from '@/composables/useAuth';

  export default {
    setup() {
      const auth = useAuth();
      provide('auth', auth);
    },
  };
</script>
<style>
#app {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: red;
}
</style>
