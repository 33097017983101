<template>
    <div class="wrapper-main-mb">
        <!-- Start Breadcrumb Area  -->
        <div class="axil-breadcrumb-area">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-12 col-md-8">
                        <div class="inner">
                            <ul class="axil-breadcrumb">
                                <li class="axil-breadcrumb-item"><router-link :to="{ name: 'Profile' }">MI CUENTA</router-link>
                                </li>
                                <li class="separator"></li>
                                <li class="axil-breadcrumb-item active" aria-current="page">TICKETS</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="tab-pane" id="nav-address" role="tabpanel" style="padding: 0 20px;">
            <div class="axil-dashboard-address">
                <div class="text-right">
                    <router-link :to="{ name: 'Create Ticket' }">
                        <b-button variant="danger">Nuevo Ticket</b-button>
                    </router-link>
                </div>
                <div v-if="isLoading">
                    <PlaceHolder></PlaceHolder>
                </div>
                <div v-else class="card-order c1" v-for="(ticket, rw) in tickets" :key="rw">
                    <div class="parent">
                        <div class="child">
                            <div class="left">
                                <div class="icon-profile">
                                    <router-link :to="{ name: 'Ticket Detail', params: { 'id': ticket.id } }">
                                        <i class="far fa-eye" style="font-size: 15px;color: #0a53be;"></i>
                                    </router-link>
                                </div>
                                <div class="info">
                                    <div style="width: 150px;">
                                        <h3 class="data name-order text-dark"><b>Orden #{{ ticket.order_id }}</b></h3>
                                    </div><br>
                                    <div v-for="(reason, rs) in reasons" :key="rs">
                                        <h3 class="data text-dark" v-if="reason.id == ticket.tickets_reason_id">
                                            {{ reason.reason }}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="info" v-if="tickets.length === 0">
                    <h3 class="data name-order text-dark text-center">No hay tickets aún</h3>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { useStore } from "vuex";
import store from "@/store";
import { useRouter } from "vue-router";
import { mapState } from "vuex";
import PlaceHolder from '@/components/PlaceHolder/WishList.vue'
import { useToast } from "vue-toastification";

export default {
    data: () => ({
        store: useStore(),
        router: useRouter(),
        user: {},
        tickets: [],
        toast: useToast(),
        reasons: []
    }),
    components:{
        PlaceHolder
    },
    computed:{
        ...mapState(['isLoading'])
    },
    mounted(){
        this.getTickets();
        this.getReasons();
    },
    methods: {
        getTickets() {
            store.dispatch("getTickets")
            .then((response) => {
                this.tickets = response.data
            })
                .catch((err) => {
                    console.log(err)
                })
        },
        getReasons(){
            store.dispatch('getTicketsReasons')
            .then((res) => {
                this.reasons = res.data;
                return res
            })
            .catch(error => {
                return error
            });
        },
    }
}
</script>
<style>
.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #164170;
    --bs-btn-border-color: none;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: transparent;
    --bs-btn-hover-border-color: none;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: transparent;
    --bs-btn-active-border-color: #0a53be;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #0d6efd;
    --bs-btn-disabled-border-color: none;
}
.boton-heart {
    background: transparent;
    border:none;
    color: #e51c21;
    font-size: 30px;
}
</style>