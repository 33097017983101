<template>
     <div class="slick-single-layout" style="padding: 0 15px;margin-top: 40%;">
          <div class="row row--15">
               <div v-if="isLoading">
                    <PlaceHolder></PlaceHolder>
               </div>
               <div class="col-xl-12 col-lg-12 col-sm-12 col-12 mb--30">
                    <div class="axil-product product-style-one">
                         <div class="flex w-full px-2 md:px-0 pt--60">
                              <div class="flex items-center space-x-1">
                                   <div class="flex items-center">
                                        <div v-for="breadcrumb in breadcrumbs" :key="breadcrumb">
                                             <span class="text-secondary-color text-small">{{ breadcrumb.name }}</span>
                                             <span class="text-danger text-small mx-1">/</span>
                                        </div>
                                        <span class="text-main-color text-small mx-1 text-align-left">{{ getFirstWords( product.simple_fields.name, 3) }}</span>
                                   </div>
                              </div>
                         </div>
                         <div class="thumbnail">
                              <Carousel v-if="images.length > 0">
                                   <Slide v-for="slide in images" :key="slide">
                                        <div class="carousel__item">
                                             <img style="width: 200px;height: 200px;margin-top: 40px;object-fit: contain;" :src="imagePath(slide.db_path)"
                                                  alt="">
                                        </div>
                                   </Slide>
                                   <template #addons>
                                        <Navigation />
                                        <Pagination />
                                   </template>
                              </Carousel>
                              <div v-else style="width: 200px;height: 200px;margin-top: 40px;;object-fit: contain;"></div>
                              <div class="modal" id="modal-share">
                                   <a href="#" class="modal-bg"></a>
                                   <div class="modal-content">
                                        <a href="#" class="modal-exit"><i class="fas fa-times"></i></a>
                                        <h3>¿Deseas compartir este producto?</h3>
                                        <div class="modal-text">
                                             <div class="row">
                                                  <a :href="'https://www.facebook.com/sharer/sharer.php?u=https://rc1.mainbitpreproduccion.shop/' + product.simple_fields.url_key"
                                                  class="button-facebook">
                                                  <i class="fab fa-facebook-square"></i>
                                                  </a>
                                                  <a :href="'https://www.instagram.com/?url=https://rc1.mainbitpreproduccion.shop/' + product.simple_fields.url_key"
                                                       class="button-instagram">
                                                       <i class="fab fa-instagram"></i>
                                                  </a>
                                                  <a :href="'https://twitter.com/intent/tweet?text==https://rc1.mainbitpreproduccion.shop/' + product.simple_fields.url_key"
                                                       class="button-twitter">
                                                       <i class="fab fa-twitter-square"></i>
                                                  </a>
                                                  <a :href="'https://api.whatsapp.com/send?text=https://rc1.mainbitpreproduccion.shop/' + product.simple_fields.url_key"
                                                       data-action="share/whatsapp/share" class="button-whatsapp">
                                                       <i class="fab fa-whatsapp-square"></i>
                                                  </a>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <div class="label-block label-right" v-if="inventory.mb_special_price && inventory.mb_special_price_to >= currentDate"
                                   style="margin-top: 15%;">
                                   <div class="product-badget" style="background: #38d10b;">OFERTA</div>
                              </div>
                              <div class="label-block label-left">
                                   <div class="product-badget" v-if="product.simple_fields.refurbished === 1"
                                        style="background: #000;">
                                        REACONDICIONADO
                                   </div>
                              </div>
                              <div class="label-block label-share">
                                   <li class="wishlist" v-if="wishlistExist" style="list-style: none;">
                                        <a href="#modal" class="axil-btn wishlist-btn colorselect" style="right: -15px;z-index: 1;border-radius: 50%;border: 10px #e72043 solid;padding: 0 !important;background-color: #e72043;">
                                             <i class="far fa-heart" style="color:#fff"></i>
                                        </a>
                                   </li>
                                   <li class="wishlist" v-else style="list-style: none;">
                                        <a class="axil-btn wishlist-btn" style="z-index: 1;border-radius: 50%;padding: 0 !important;border: none"
                                             @click.prevent="addWishlist()">
                                             <i class="far fa-heart"></i>
                                        </a>
                                   </li>
                              </div>
                              <div class="label-block label-left" style="left: auto;right: 10px;margin-top: 70% !important;">
                                   <a href="#modal-share">
                                       <img src="@/assets/images/icons/compartir.png" alt="Product Images" style="width:25px;"> 
                                   </a>
                              </div>
                         </div>
                         <div class="product-content">
                              <div class="inner">
                                   <div class="title">
                                        <span class="w-full text-black text-large" v-if="product.simple_fields">
                                             {{ product.simple_fields.name }}
                                        </span>
                                   </div><br>
                                   <div class="product-price-variant">
                                        <div class="row">
                                             <div class="text-left col-6" style="display: flex;justify-content: left;">
                                                  <label for="" class="font-size-15">{{ averageRating }} &nbsp;&nbsp;</label>
                                                  <vue3starRatings v-model="averageRating" class="disabled-rating" :star-size="13" />
                                             </div>
                                             <div class="col-6" style="display: flex;justify-content: right;">
                                                  <label class="text-small text-success" for="">{{ product.inventory.qty }} en stock</label>
                                             </div>
                                        </div>
                                        <div class="my-4" v-if="inventory.mb_special_price && inventory.mb_special_price_to >= currentDate">
                                             <span class="text-3xl font-bold text-align-left">
                                                  {{ $filters.money(inventory.mb_special_price) }}
                                             </span>
                                             <span class="text-3xl old-price text-align-left">
                                                  {{ $filters.money(inventory.mb_price) }}
                                             </span>
                                        </div>
                                        <div class="my-4" v-else>
                                             <span class="text-3xl font-bold text-align-left">
                                                  {{ $filters.money(inventory.mb_price) }}
                                             </span>
                                        </div>
                                   </div><br>
                                   <div class="col-lg-12 mb--40">
                                        <div v-if="showPopup" class="popup">
                                             <img style="width: 100px;height: 100px;object-fit: contain;" :src="imagePath(images[0].db_path)"
                                                  alt="">
                                                  <span class="text-main-color text-small mx-1 text-align-left">{{ getFirstWords( product.simple_fields.name, 3) }}</span>
                                                  <div class="my-4" v-if="inventory.mb_special_price && inventory.mb_special_price_to >= currentDate">
                                                       <span class="text-large font-bold text-align-left">
                                                            {{ $filters.money(inventory.mb_special_price) }}
                                                       </span>
                                                       <span class="text-large old-price text-align-left">
                                                            {{ $filters.money(inventory.mb_price) }}
                                                       </span>
                                                  </div>
                                                  <div class="my-4" v-else>
                                                       <span class="text-large font-bold text-align-left">
                                                            {{ $filters.money(inventory.mb_price) }}
                                                       </span>
                                                  </div>
                                                  <ul class="product-action">
                                                       <li class="add-to-cart">
                                                            <AddToCartBtn :product="product" @click.prevent="add()" style="width: 100% !important;">
                                                                 <i class="fa fa-shopping-cart text-white" aria-hidden="true"></i> Comprar
                                                            </AddToCartBtn>
                                                       </li>
                                                  </ul>
                                        </div>
                                        <div class="single-product-content">
                                             <div class="inner">
                                                  <!-- Start Product Action Wrapper  -->
                                                  <div class="product-action-wrapper d-flex-center">
                                                       <div class="row" style="width: 100%;">
                                                            <div class="col-4">
                                                                 <!-- Start Quentity Action  -->
                                                                 <select v-model="quantity" id="numbers">
                                                                      <option v-for="number in numbers" :key="number" :value="number">
                                                                      {{ number }}
                                                                      </option>
                                                                 </select>
                                                                 <!-- End Quentity Action  -->
                                                            </div>
                                                            <div class="col-8">
                                                                 <!-- Start Product Action  -->
                                                                 <ul class="product-action d-flex-center mb--0">
                                                                      <li class="add-to-cart">
                                                                           <AddToCartBtn :product="product" @click.prevent="add()">
                                                                                <i class="fa fa-shopping-cart text-white" aria-hidden="true"></i> Comprar
                                                                           </AddToCartBtn>
                                                                      </li>
                                                                      <!--li class="add-to-cart" v-if="product.inventory && isLogged">
                                                                           <AddToCartBtn :product="product" @click.prevent="addCartTemp()">
                                                                                Añadir al carrito
                                                                           </AddToCartBtn>
                                                                      </li-->
                                                                 </ul>
                                                                 <!-- End Product Action  -->
                                                            </div>
                                                       </div>
                                                  </div>
                                                  <br><br>
                                                  <div class="product-rating" v-if="reviews.length > 0">
                                                       <div class="review-link w-full text-black text-large px-4 md:px-0">
                                                            <a href="#reviews">Opiniones de clientes ({{
                                                                 reviews.length }})</a>
                                                       </div>
                                                  </div>
                                                  <ul class="product-meta">
                                                       <li class="w-full text-large"><i class="fal fa-check"></i>{{ product.inventory.qty }} en stock
                                                       </li>
                                                       <li class="w-full text-large"><i class="fal fa-check"></i>Entrega gratuita disponible</li>
                                                       <li class="w-full text-large"><i class="fal fa-check"></i>Transacción segura</li>
                                                       <!--li><i class="fal fa-check"></i>30% de descuento Use el código: MOTIVE30</li-->
                                                  </ul>
                                                  <!-- End Product Action Wrapper  -->
                                                  <div>
                                                       <!-- Using value -->
                                                       <b-button v-b-toggle="'collapse-2-description1'" class="m-1 btn-collap" style="margin-bottom: 15px !important;margin-top: 15px !important;">
                                                            <div class="row">
                                                                 <div class="col-10 w-full text-large text-align-left title-collap">Detalles técnicos</div>
                                                                 <div class="col-2 icon-right-collapse"><i style="margin-right: -37px;" class="fa fa-angle-down text-right" aria-hidden="true"></i></div>
                                                            </div>
                                                       </b-button>

                                                       <!-- Element to collapse -->
                                                       <b-collapse id="collapse-2-description1">
                                                       <b-card class="w-full text-large text-justify text-card-body">
                                                            <table class="table table-striped">
                                                                 <tbody>
                                                                 <tr v-for="attributes in CategoryAttributes" :key="attributes">
                                                                      <th scope="row" class="text-left text-large">
                                                                           <b>{{ attributes.label }}</b> 
                                                                      </th>
                                                                      <td class="text-left text-large">
                                                                           <label for="">
                                                                                {{ attributes.value }}
                                                                           </label>
                                                                      </td>
                                                                 </tr>
                                                                 </tbody>
                                                            </table>
                                                       </b-card>
                                                       </b-collapse>

                                                       <!-- Using value -->
                                                       <b-button v-b-toggle="'collapse-2-description2'" class="m-1 btn-collap" style="margin-bottom: 15px !important;">
                                                            <div class="row">
                                                                 <div class="col-10 w-full text-large text-align-left title-collap">Formas de pago</div>
                                                                 <div class="col-2 icon-right-collapse"><i style="margin-right: -37px;" class="fa fa-angle-down text-right" aria-hidden="true"></i></div>
                                                            </div>
                                                       </b-button>

                                                       <!-- Element to collapse -->
                                                       <b-collapse id="collapse-2-description2">
                                                       <b-card class="w-full text-large text-justify text-card-body">
                                                            Transacciones seguras y sin complicaciones con nuestras opciones de pago flexibles y facilidades de crédito. Conoce más sobre los diversos métodos de pago aceptados, los planes de pago a plazos y las ofertas exclusivas de crédito disponibles para que tu experiencia de compra sea perfecta.
                                                       </b-card>
                                                       </b-collapse>

                                                       <!-- Using value -->
                                                       <b-button v-b-toggle="'collapse-2-description3'" class="m-1 btn-collap" style="margin-bottom: 15px !important;">
                                                            <div class="row">
                                                                 <div class="col-10 w-full text-large text-align-left title-collap">Descripción</div>
                                                                 <div class="col-2 icon-right-collapse"><i style="margin-right: -37px;" class="fa fa-angle-down text-right" aria-hidden="true"></i></div>
                                                            </div>
                                                       </b-button>

                                                       <!-- Element to collapse -->
                                                       <b-collapse id="collapse-2-description3">
                                                       <b-card class="w-full text-large text-justify text-card-body">{{ product.simple_fields.description }}</b-card>
                                                       </b-collapse>
                                                  </div>
                                                  <div class="row item-simple-product">
                                                       <div class="col-4">
                                                            <img style="width: 60%;"
                                                                 src="https://rc1.mainbitpreproduccion.shop/vendor/mainbit/footer/icons/Compras_seguras.png"
                                                                 alt="Pagos">
                                                            <span class="text-small">Pagos y Seguridad</span>
                                                       </div>
                                                       <div class="col-4">
                                                            <img style="width: 60%;"
                                                                 src="https://rc1.mainbitpreproduccion.shop/vendor/mainbit/footer/icons/Entregas_garantizadas.png"
                                                                 alt="Envio">
                                                            <span class="text-small">Devolución sin costo</span>
                                                       </div>
                                                       <div class="col-4">
                                                            <img style="width: 60%;"
                                                                 src="https://rc1.mainbitpreproduccion.shop/vendor/mainbit/footer/icons/Entregas_garantizadas.png"
                                                                 alt="Envio">
                                                            <span class="text-small">Envíado por MainbitShop</span>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                        <div class="modal" id="modal">
                                             <a href="#" class="modal-bg"></a>
                                             <div class="modal-content">
                                                  <a href="#" class="modal-exit"><i class="fas fa-times"></i></a>
                                                  <h3 class="modal-title axil-breadcrumb-item">¿Eliminar de la lista de
                                                       deseos?</h3>
                                                  <div class="modal-text">
                                                       <div class="row">
                                                            <div class="col-6">
                                                                 <b-button variant="light">
                                                                      <a class="cancel-modal" id="cancel-modal" href="#">Cancelar</a>
                                                                 </b-button>
                                                            </div>
                                                            <div class="col-6">
                                                                 <b-button variant="success"
                                                                      @click.prevent="removeItem()">Aceptar</b-button>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                        <div class="single-product-content">
                                             <details>
                                                  <summary class="w-full text-large title-reel" style="color: #3b77f0;">Déjanos tu opinión</summary>
                                                  <vue3starRatings v-model="rating" />
                                                  <input v-model="titleReview" placeholder="Título" />
                                                  <textarea placeholder="Escribe tu opinión ..." rows="20" cols="40"
                                                       v-model="commentReview">
                                                  </textarea>
                                                  <b-button variant="info" @click.prevent="addReview()">Envíar</b-button>
                                             </details>
                                        </div><br><br>
                                        <div class="section-title-wrapper">
                                             {{ related.length }}
                                             <span class="title-highlighter highlighter-primary text-large title-reel">
                                                  Productos relacionados
                                             </span>
                                             <ProductReel :Reel="related"></ProductReel>
                                        </div>
                                        <div class="section-title-wrapper">
                                             <span class="title-highlighter text-large highlighter-primary title-reel">
                                                  Otros clientes también compraron
                                             </span>
                                             <ProductReel :Reel="up_sell"></ProductReel>
                                        </div>
                                        <div v-if="averageRating > 0">
                                             <h3 class="text-very-large">{{ averageRating }} Comentarios y opiniones.</h3>
                                             <div class="row" v-for="([key, value]) in entries" :key="key">
                                                  <span class="col-2 no-padding fs16 fw6">
                                                       {{ key }} <i class="fas fa-star star-bar"></i>
                                                  </span>
                                                  <div class="col-8 rating-bar" title="{{ key }}%">
                                                       <div :style="{ width: value + '%' }" class="filled-bar"></div>
                                                  </div>
                                                  <div class="col-2">{{ value }}%</div>
                                             </div><br>
                                        </div>
                                        <div>
                                           <ComparableProduct :Reel="product.id"></ComparableProduct>  
                                        </div>
                                        <div v-if="reviews.length > 0" id="reviews" class="single-product-content">
                                             <h5 class="title text-very-large" style="color: #3b77f0;">Opiniones del producto</h5>
                                             <div class="inner single-review" v-for="(review, rw) in reviews" :key="rw">
                                                  <div>
                                                       <div class="row">
                                                            <div class="col-6 reviews-card text-large"><br>
                                                                 {{ review.name }}
                                                            </div>
                                                            <div class="col-6"><br>
                                                                 <div class="product-rating">
                                                                      <div class="star-rating"
                                                                           v-for="(star, n) in review.rating" :key="n">
                                                                           <i class="fas fa-star"></i>
                                                                      </div>
                                                                      <div class="star-rating"
                                                                           v-for="(star, n) in 5 - review.rating" :key="n">
                                                                           <i class="far fa-star"></i>
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                       <h2 class="title-review text-large">{{ review.title }}</h2>
                                                       <h2 class="comment-review text-large">"{{ review.comment }}"</h2>
                                                       <div class="row">
                                                            <div class="col-4"></div>
                                                            <div class="col-8 text-rigth">
                                                                 <h3 class="text-small">{{ $filters.inDate(review.created_at) }}</h3>
                                                            </div>
                                                       </div><br>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>
<script>
import { Carousel, Navigation, Slide, Pagination } from 'vue3-carousel'
import { getProductHistory, addProductToHistory } from '@/utils/productHistory';
import { useRouter } from "vue-router";
import ProductReel from "@/components/Reels/ProductReel.vue";
import ComparableProduct from "@/components/Reels/ComparableProduct.vue";
import vue3starRatings from "vue3-star-ratings";
import AddToCartBtn from './Partials/AddToCartBtn.vue'
import store from '@/store'
import { useToast } from "vue-toastification";
import PlaceHolder from '@/components/PlaceHolder/ProductDetail.vue'
import { mapState } from 'vuex'
import { useImagePath } from '@/composables/useImagePath'; 

export default {
     props: {
          product: {
               type: Object,
               required: true
          },
          images: {
               type: Object,
               required: true
          }
     },
     components: {
          AddToCartBtn,
          Carousel,
          Slide,
          Navigation,
          Pagination,
          PlaceHolder,
          vue3starRatings,
          ProductReel,
          ComparableProduct
     },
     data: () => ({
          quantity: 1,
          toast: useToast(),
          inventory: [],
          reviews: [],
          commentReview: null,
          titleReview: null,
          rating: 0,
          products: [],
          wishlistExist: false,
          totalQuantity: 0,
          related: [],
          up_sell: [],
          comparable: [],
          currentDate: null,
          averageRating: 0,
          percentageRatings: '',
          productHistory: [],
          productAttributes: [],
          router: useRouter(),
          wishlist: store.state.user.wishlist,
          numbers: [1, 2, 3, 4, 5],
          CategoryAttributes: [],
          breadcrumbs: [],
          inputText: '', 
          firstWords: '',
          showPopup: false
     }),
     computed: {
          isLogged() {
               if (!store.state.user.token) {
                    return true;
               }
               return false;
          },
          ...mapState(['isLoading', 'productsTemp', 'productsCartTemp','recommendedProducts']),
          entries() {
               // Verificamos si hay al menos un objeto en el array
               if (this.percentageRatings.length > 0) {
               return Object.entries(this.percentageRatings[0]); // Accedemos al primer objeto
               }
               return []; // Retornamos un array vacío si no hay objetos
          },
     },
     mounted() {
          this.getAverageRating();
          this.productInformation();
          if (store.state.user.token) {
               this.getWishlist();
               this.getCartUser();
          }
          this.relatedProducts();
          this.currentDate = this.formatDate(new Date());
          this.productHistory = getProductHistory();
          this.viewProduct(this.product);
          this.comparableProducts();
          const storedProducts = localStorage.getItem('productsCartTemp');
          if (storedProducts) {
          // Si hay productos en localStorage, parsearlos y asignarlos a `productsCartTemp`
               this.productsCartTemp = JSON.parse(storedProducts);
          }
          // Añadir el listener de scroll cuando el componente se monte
          window.addEventListener('scroll', this.handleScroll);
     },  
     beforeUnmount() {
          // Remover el listener cuando el componente se destruya
          window.removeEventListener('scroll', this.handleScroll);
     }, 
     methods: {
          viewProduct(product) {
               // Llamar a la función para agregar el producto al historial
               addProductToHistory(product);

               // Actualizar el historial en la vista
               this.productHistory = getProductHistory();
               
               // Aquí puedes agregar más lógica, como navegar a la página de detalles del producto
               // this.$router.push(`/product/${product.id}`);
          },
          getCartUser(){
               store.dispatch('getCart');
          },
          add() {
               if (store.state.user.token) {
                    store.dispatch("addProductToCart", {
                         quantity: this.quantity,
                         product_id: this.product.id,
                         is_buy_now: 0,
                         inventory_id: 0
                    })
                    .then((res) => {
                         if(!res.data.success){
                              this.toast.error(res.data.errors[0])
                         }else{
                              this.toast.success('Articulo añadido al carrito')
                              //document.getElementById('cart-dropdown-btn').click();
                              this.router.push({ name: 'Checkout' });
                         }
                         store.dispatch("getCart")
                         
                    }).catch((err) => {
                         console.log(err);
                    });
               } else {
                    let exists = false
                    this.productsCartTemp.forEach((prod) => {
                         if (prod.productId == this.product.id) {
                              exists = true
                         }
                    });

                    if (this.quantity > this.product.inventory.qty && exists === false) {
                         this.productsTemp.push({ products: this.product, quantity: this.product.inventory.qty })
                         this.productsCartTemp.push({ productId: this.product.id, quantity: this.product.inventory.qty })
                         this.toast.success('Articulo añadido al carrito')
                    }
                    if (exists === false && this.quantity < this.product.inventory.qty) {
                         this.productsTemp.push({ products: this.product, quantity: parseInt(this.quantity) })
                         this.productsCartTemp.push({ productId: this.product.id, quantity: parseInt(this.quantity) })
                         this.toast.success('Articulo añadido al carrito')
                    }
                    if (exists === true) {
                         this.productsTemp.filter(product => {
                              if (product.products.id == this.product.id) {
                                   this.total = product.quantity += parseInt(this.quantity)
                              }
                         })
                         this.updateProductsTemp();
                         this.toast.success('Articulo añadido al carrito')
                    }
                    
                    localStorage.setItem('productsCartTemp', JSON.stringify(this.productsCartTemp));
               }
          },
          updateProductsTemp() {
               const indiceElemento = this.productsTemp.findIndex(c => c.products.id == this.product.id)
               let newProductsTemp = [...this.productsTemp]
               newProductsTemp[indiceElemento] = { ...newProductsTemp[indiceElemento], quantity: this.total }
               this.productsCartTemp.splice(0, this.productsCartTemp.length)
               this.productsTemp.forEach((prod) => {
                    if(this.total > this.product.inventory.qty){
                         this.productsCartTemp.push({ productId: prod.products['id'], quantity: this.product.inventory.qty })
                    }else{
                         this.productsCartTemp.push({ productId: prod.products['id'], quantity: prod['quantity'] })
                    }
               });
               
          },
          addWishlist() {
               if (store.state.user.token) {
                    this.isLoading = true
                    store.dispatch("addProductToWishlist", {
                         productId: this.product.id
                    }).then(() => {
                         this.toast.success('Artículo añadido a favoritos')
                    }).catch((err) => {
                         console.log(err);
                    }).finally(() => {
                         this.getWishlist()
                    });
               } else {
                    this.toast.error('Inicia sesión para poder realizar esta acción')
               }
          },
          addReview() {
               if (store.state.user.token) {
                    store.dispatch("createProductReview", {
                         "title": this.titleReview,
                         "comment": this.commentReview,
                         "rating": this.rating,
                         "product_id": this.product.id
                    }).then(() => {
                         this.toast.success('Reseña agregada exitosamente.')
                    }).catch((err) => {
                         console.log(err);
                    }).finally(() => {
                         this.commentReview = null
                         this.titleReview = null
                         this.rating = 0
                    });
               } else {
                    this.toast.error('Inicia sesión para poder realizar esta acción')
               }
          },
          productInformation() {
               this.inventory = this.product.inventory;
               this.reviews = this.product.productReviews.filter(review => review.status === "approved")
          },
          removeItem() {
               this.isLoading = true
               store.dispatch('removeProductFromWishlist', {
                    productId: this.product.id,
               }).then((res) => {
                    this.toast.success(res.data.messageForUser)
               }).catch((err) => {
                    console.log(err);
               }).finally(() => {
                    store.dispatch("getWishlist")
                    this.wishlist.pop()
                    this.wishlistExist = false;

                    const button = document.getElementById('cancel-modal');
                    button.click();
               });
          },
          getWishlist() {
               store.dispatch('getWishlist')
               .then((res) => {
                    res.data.wishlist.filter(list => {
                        if(list.product_id == this.product.simple_fields.product_id){
                              this.wishlistExist = true;
                        }
                    })
               })
               .catch(error => {
                    console.error('Error fetching getWishlist:', error);
               });
          },
          relatedProducts(){
               store.dispatch('relatedProducts', this.product.id)
               .then((res) => {
                   this.CategoryAttributes = res.data.payload.category_attributes.attributes;
                   this.related = res.data.payload.related.related.slice(this.related.length - 10);
                   this.up_sell = res.data.payload.related.up_sells.slice(this.up_sell.length - 10);
                   res.data.payload.related.related.forEach((prod) => {
                    this.recommendedProducts.push(prod);
                   })
               })
               .catch(error => {
                    console.error('Error fetching getWishlist:', error);
               });
          },
          formatDate(date) {
               const day = String(date.getDate()).padStart(2, '0');
               const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses empiezan en 0
               const year = date.getFullYear();
               return `${year}-${month}-${day}`;
          },
          getAverageRating(){
               store.dispatch("getAverageRating", {
                    productId: this.product.id,
               }).then((res) => {
                    if(res.data.message){
                         this.averageRating = 0;
                    }else{
                       this.averageRating = res.data  
                    }
               }).catch((err) => {
                    console.log(err);
               });

               store.dispatch("getPercentageRatings", {
                    productId: this.product.id,
               }).then((res) => {
                    this.percentageRatings = [res.data]
               }).catch((err) => {
                    console.log(err);
               });
          },
          comparableProducts(){
               store.dispatch("comparableProducts", {
                    productId: this.product.id,
               }).then((res) => {
                   this.productAttributes = res.data.category_attributes.attributes;
                   this.breadcrumbs = res.data.category_attributes.breadcrumbs;
               }).catch((err) => {
                    console.log(err);
               });
          },
          imagePath(dbPath) {
               const { imagePath } = useImagePath(); // Obtener la función imagePath desde el composable
               return imagePath(dbPath); // Llamar a la función para obtener la URL
          },
          getFirstWords(str, numWords) {
               if (!str) return "ERROR"; // Si no hay texto, retorna un error
               const words = str.split(' '); // Divide el texto en palabras
               if (words.length <= numWords) return str; // Si el número de palabras es menor o igual a numWords, devuelve el texto completo
               return words.slice(0, numWords).join(' '); // Retorna las primeras 'numWords' palabras
          },
          handleScroll() {
               // Si el scroll es mayor a 100px, muestra el popup
               if (window.scrollY > 600) {
               this.showPopup = true;
               } else {
               this.showPopup = false;
               }
          },
          closePopup() {
               this.showPopup = false; // Cierra el popup al hacer clic en el botón
          },
     }
}
</script>

<style scoped>

.popup {
     position: fixed;
    top: 110px;
    left: 70%;
    transform: translateX(-50%);
    background-color: #fff;
    color: #000;
    padding: 10px 20px;
    border-radius: 5px;
    z-index: 1000;
    width: 190px;
    border: 0.5px solid #0000002e;
}

.popup button {
  background-color: red;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.popup button:hover {
  background-color: darkred;
}
</style>