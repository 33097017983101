<template>
    <div>
        <div class="wrapper-main-mb" style="margin-top: 55%;">
            <Carousel>
                <Slide v-for="slide in images" :key="slide">
                    <div class="carousel__item">
                        <img :src="slide.url" alt="">
                    </div>
                </Slide>
                <template #addons>
                    <Navigation />
                    <!--Pagination /-->
                </template>
            </Carousel>
            <div class="section-title-wrapper">
                <span class="title-highlighter highlighter-primary text-xl md:text-2xl font-semibold text-gray-900">
                    <i class="far fa-shopping-basket"></i>
                    Productos Destacados
                </span>
                <SplitCategoryReel :Reel="featuredProducts"></SplitCategoryReel>
                <p v-if="statusFP !== 200">
                    No disponible
                </p>
            </div>
            <div class="section-title-wrapper" v-if="productHistory.length > 0">
                <span class="title-highlighter highlighter-primary text-xl md:text-2xl font-semibold text-gray-900">
                    <i class="far fa-shopping-basket"></i>
                    Productos vistos recientemente
                </span>
                <SplitCategoryReel :Reel="productHistory"></SplitCategoryReel>
            </div>
            <div class="section-title-wrapper">
                <span class="title-highlighter highlighter-primary text-xl md:text-2xl font-semibold text-gray-900">
                    <i class="far fa-shopping-basket"></i>
                    Tabletas
                </span>
                <CategoryReel :Reel="reel"></CategoryReel>
                <p v-if="status1 !== 200">
                    No disponible
                </p>
            </div>
            <div class="section-title-wrapper container products">
                <div class="row">
                    <div class="col one"><br>
                        <span class="title-highlighter text-white">PC</span>
                        <img src="../assets/images/icons/pc-categoria.png" width="90%">
                    </div>
                    <div class="col two"><br>
                        <span class="title-highlighter text-white">ACCESORIOS</span>
                        <img src="../assets/images/icons/accesorios.png" width="73%">
                    </div>
                </div>
                <div class="row">
                    <div class="col three"><br>
                        <span class="title-highlighter text-black">IMPRESORAS</span>
                        <img src="../assets/images/icons/impresora.png" width="70%">
                    </div>
                    <div class="col four"><br>
                        <span class="title-highlighter text-black">VIDEO</span><br><br>
                        <img src="../assets/images/icons/camara.png">
                    </div>
                </div>
            </div>
            <div class="section-title-wrapper">
                <img src="../assets/images/banner/player_one_1.png">
            </div>
            <div class="section-title-wrapper">
                <span class="title-highlighter highlighter-primary text-xl md:text-2xl font-semibold text-gray-900">
                    <i class="far fa-shopping-basket"></i>
                    Laptops
                </span>
                <CategoryReel :Reel="reel2"></CategoryReel>
                <p v-if="status2 !== 200">
                    No disponible
                </p>
            </div>
            <div class="section-title-wrapper">
                <span class="title-highlighter highlighter-primary text-xl md:text-2xl font-semibold text-gray-900">
                    <i class="far fa-shopping-basket"></i>
                    Smart life
                </span>
                <CategoryReel :Reel="reel3"></CategoryReel>
                <p v-if="status3 !== 200">
                    No disponible
                </p>
            </div>
            <div class="section-title-wrapper">
                <span class="title-highlighter highlighter-primary text-xl md:text-2xl font-semibold text-gray-900">
                    <i class="far fa-shopping-basket"></i>
                    Soluciones de software
                </span>
                <CategoryReel :Reel="reel4"></CategoryReel>
                <p v-if="status4 !== 200">
                    No disponible
                </p>
            </div>
            <div class="section-title-wrapper row">
                <div class="col-6 text-right">
                    <img src="https://rc1.mainbitpreproduccion.shop//storage/velocity/images/headphones.webp">
                </div>
                <div class="col-6 text-left">
                    <img src="https://rc1.mainbitpreproduccion.shop//storage/velocity/images/watch.webp"><br>
                    <img src="https://rc1.mainbitpreproduccion.shop//storage/velocity/images/kids-2.webp">
                </div>
            </div>
            <div class="section-title-wrapper">
                <span class="title-highlighter highlighter-primary text-xl md:text-2xl font-semibold text-gray-900">
                    <i class="far fa-shopping-basket"></i>
                    Productos Nuevos
                </span>
                <SplitCategoryReel :Reel="newProducts"></SplitCategoryReel>
                <p v-if="statusNP !== 200">
                    No disponible
                </p>
            </div>
            <div class="section-title-wrapper">
                <span class="title-highlighter highlighter-primary text-xl md:text-2xl font-semibold text-gray-900">
                    <i class="far fa-shopping-basket"></i>
                    Almacenamiento
                </span>
                <CategoryReel :Reel="reel5"></CategoryReel>
                <p v-if="status5 !== 200">
                    No disponible
                </p>
            </div>
            <div class="section-title-wrapper">
                <span class="title-highlighter highlighter-primary text-xl md:text-2xl font-semibold text-gray-900">
                    <i class="far fa-shopping-basket"></i>
                    Gaming
                </span>
                <CategoryReel :Reel="reel6"></CategoryReel>
                <p v-if="status6 !== 200">
                    No disponible
                </p>
            </div>
            <div class="section-title-wrapper">
                <img src="https://rc1.mainbitpreproduccion.shop/themes/velocity/assets/images/home/10000.png">
            </div>
            <div class="section-title-wrapper">
                <span class="title-highlighter highlighter-primary text-xl md:text-2xl font-semibold text-gray-900">
                    <i class="far fa-shopping-basket"></i>
                    Productos Apple
                </span>
                <CategoryReel :Reel="reel7"></CategoryReel>
                <p v-if="status7 !== 200">
                    No disponible
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { Carousel, Navigation, Slide } from 'vue3-carousel';
import CategoryReel from "@/components/Reels/CategoryReel.vue";
import SplitCategoryReel from "@/components/Reels/SplitCategoryReel.vue";
import { getProductHistory } from '@/utils/productHistory';
import store from "@/store";
import { ref } from 'vue';

export default {
    name: 'IndexView',
    components: {
        Carousel,
        Slide,
        Navigation,
        CategoryReel,
        SplitCategoryReel
    },
    data: () => ({
        featuredProducts: [],
        statusFP: {},
        newProducts: [],
        statusNP: {},
        reel: [],
        status1: {},
        reel2: [],
        status2: {},
        reel3: [],
        status3: {},
        reel4: [],
        status4: {},
        reel5: [],
        status5: {},
        reel6: [],
        status6: {},
        reel7: [],
        status7: {},
        productHistory: [],
    }),
    setup() {
        const images = ref([
            {
                id: 1,
                url: require('../assets/images/banner/banner-mb.png'),
                name: "image 1",

            },
            {
                id: 2,
                url: require('../assets/images/banner/banner2.png'),
                name: "image 2",

            },
            {
                id: 3,
                url: require('../assets/images/banner/banner3.png'),
                name: "image 3",

            },
        ]);

        return { images };
    },
    mounted() {
        this.getFeaturedProducts();
        this.getNewProducts();
        this.getReelTabletas();
        this.getReelLaptops();
        this.getReelSmart();
        this.getReelSoftware();
        this.getReelAlmacenamiento();
        this.getReelGaming();
        this.createNewCart();
        if (store.state.user.token) {
            this.getCartUser();
        }
        this.productHistory = getProductHistory();
    },
    methods: {
        getCartUser(){
            store.dispatch('getCart');
        },
        createNewCart() {
            store.dispatch('createNewCart')
            .then(() => {
                store.dispatch("getCart")

            }).catch(() => {
                this.toast.error("Ha ocurrido un error, intentalo más tarde")
            })
        },
        getFeaturedProducts() {
            store.dispatch('featuredProducts')
                .then(response => {
                    this.featuredProducts = response.data.products.slice(this.featuredProducts.length - 10)
                    this.statusFP = response.status;
                })
                .catch(error => {
                    console.error('Error fetching featuredProducts:', error);
                });
        },
        getNewProducts() {
            store.dispatch('newProducts')
                .then(response => {
                    this.newProducts = response.data.products.slice(this.newProducts.length - 10)
                    this.statusNP = response.status;
                })
                .catch(error => {
                    console.error('Error fetching featuredProducts:', error);
                });
        },
        getReelTabletas() {
            store.dispatch('productCategory', 'tabletas')
                .then((res) => {
                    this.reel = res.data.category.products.slice(this.reel.length - 6);
                    this.status1 = res.status;
                }).catch((err) => {
                    console.log(err);
                });
        },
        getReelLaptops() {
            store.dispatch('productCategory', 'portatiles')
                .then((res) => {
                    this.reel2 = res.data.category.products.slice(this.reel2.length - 6);
                    this.status2 = res.status;
                }).catch((err) => {
                    console.log(err);
                });
        },
        getReelSmart() {
            store.dispatch('productCategory', 'smarth-home')
                .then((res) => {
                    this.reel3 = res.data.category.products.slice(this.reel3.length - 6);
                    this.status3 = res.status;
                }).catch((err) => {
                    console.log(err);
                });
        },
        getReelSoftware() {
            store.dispatch('productCategory', 'software')
                .then((res) => {
                    this.reel4 = res.data.category.products.slice(this.reel4.length - 6);
                    this.status4 = res.status;
                }).catch((err) => {
                    console.log(err);
                });
        },
        getReelAlmacenamiento() {
            store.dispatch('productCategory', 'almacenamiento')
                .then((res) => {
                    this.reel5 = res.data.category.products.slice(this.reel5.length - 6);
                    this.status5 = res.status;
                }).catch((err) => {
                    console.log(err);
                });
        },
        getReelGaming() {
            store.dispatch('productCategory', 'games')
                .then((res) => {
                    this.reel6 = res.data.category.products.slice(this.reel6.length - 6);
                    this.status6 = res.status;
                }).catch((err) => {
                    console.log(err);
                });
        },
        getReelApple() {
            store.dispatch('productCategory', 'home-apple')
                .then((res) => {
                    this.reel7 = res.data.category.products.slice(this.reel7.length - 6);
                    this.status7 = res.status;
                }).catch((err) => {
                    console.log(err);
                });
        },
    }
}
</script>

<style>
    .carousel__item {
        min-height: 200px;
        width: 100%;
        background-color: transparent;
        color: var(--vc-clr-white);
        font-size: 20px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .carousel__slide {
        padding: 10px;
    }

    .carousel__prev,
    .carousel__next {
        box-sizing: content-box;
    }

    .carousel__prev,
    .carousel__next {
        top: 50%;
    }

    .products {
        padding: 0 21.5px;
    }

    .one {
        background: #ffb3ba;
    }

    .two {
        background: #3B6280;
    }

    .three {
        background: #baffc9
    }

    .four {
        background:#ffdfba;
    }
</style>