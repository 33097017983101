import { API_BASE_URL } from '@/config/config';

export const useImagePath = () => {
  
    const imagePath = (dbPath) => {
      return `${API_BASE_URL}/services/shop/getImage?path=${encodeURIComponent(dbPath)}&width=300&height=300&aspect_ratio=true&quality=8&cache_image=false`;
    };
  
    return {
      imagePath
    };
  };
  