<template>
    <div class="tab-pane" id="nav-account" role="tabpanel" style="padding: 0 20px;">
        <div class="col-lg-9">
            <br><br>
            <!-- Start Breadcrumb Area  -->
            <div class="axil-breadcrumb-area">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-12 col-md-8">
                            <div class="inner">
                                <ul class="axil-breadcrumb">
                                    <li class="axil-breadcrumb-item"><router-link :to="{ name: 'Profile' }">MI CUENTA</router-link>
                                    </li>
                                    <li class="separator"></li>
                                    <li class="axil-breadcrumb-item active" aria-current="page">INFORMACIÓN PERSONAL</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Breadcrumb Area  -->
            <div>
                <ul v-if="isLoading">
                    <div>
                        <b-spinner variant="danger" class="position-loader" type="grow"
                            label="Cargando..."></b-spinner>
                    </div>
                </ul>
            </div>
            <div class="axil-dashboard-account">
                <div class="account-details-form" v-for="(row, key) in data" :key="key">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="row text-left">
                                <div class="col-10">
                                    <h3 class="text-very-large">Datos personales</h3><br>
                                </div>
                                <div class="col-2">
                                    <span style="cursor: pointer;" @click="edit1()" class="cart-close sidebar-close"><i class="fas fa-edit fa-2x"></i></span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="text-large">Nombre</label>
                                <input v-if="isDisabled" :value="row.first_name + ' ' + row.last_name" :disabled="isDisabled" type="text" class="form-control text-large">
                                <input v-else v-model="row.first_name" @input="updateValueFirstName" :disabled="isDisabled" type="text" class="form-control text-large">
                            </div>
                            <div class="form-group" v-if="!btn1Disabled">
                                <label class="text-large">Apellidos</label>
                                <input v-model="row.last_name" @input="updateValueLastName"  type="text" class="form-control text-large">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label class="text-large">Genero</label>
                                <select v-model="row.gender" id="options" @input="updateValueGender" :disabled="isDisabled" class="text-large">
                                    <option disabled value="">Please select one</option>
                                    <option v-for="option in options" :key="option.value" :value="option.value">
                                    {{ option.text }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label class="text-large">Fecha de nacimiento</label>
                                <input :disabled="isDisabled" v-model="row.date_of_birth" @input="updateValueDateOfBirth" type="date" class="form-control text-large">
                            </div>
                        </div>
                        <b-button @click="updateCustomer()" v-if="!btn1Disabled" style="margin: auto;width: 40%;top: -16px;" variant="danger">Guardar</b-button>
                        <b-button @click="cancel()" v-if="!btn1Disabled" style="margin: auto;width: 40%;top: -16px;" variant="info">Cancelar</b-button>
                    </div>
                    <h3 class="text-very-large">Datos de contacto</h3><br>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label class="text-large">Correo electrónico</label>
                                <input type="text" class="form-control text-large" :value="email" :disabled="isDisabled2">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group"><br>
                                <label class="text-large">Teléfono guardado</label>
                                <span class="text-large" v-if="row.is_verified_phone === 0" style="color: red;">No verificado</span>
                                <span class="text-large" v-if="row.is_verified_phone === 1" style="color: green;">Verificado</span>
                                <input type="text" class="form-control text-large" :value="row.phone" :disabled="isDisabled2">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import store from '@/store'
import { mapState } from "vuex";
import { useToast } from "vue-toastification";
import {useRouter} from "vue-router";

export default {
    name: 'UserProfile',
    data() {
        return {
            toast: useToast(),
            row:{
                first_name:'',
                last_name:'',
                gender:'',
                date_of_birth:'',
            },
            options: [  
                { value: 'M', text: 'Hombre' },
                { value: 'F', text: 'Mujer' },
                { value: 'H', text: 'Prefiero no decir' },
            ],
            data: [],
            email: '',
            showModal: false,
            isDisabled: false,
            isDisabled2: false,
            showBtn1: false,
            router: useRouter()
        }
    },
    mounted(){
        if (store.state.user.token){
            this.getProfile();
        }
        this.toggleDisabled();
        this.toggleDisabled2();
        this.btn1Disabled();
        this.email = store.state.email;
    },
    computed: {
        ...mapState(['isLoading'])
    },
    methods: {
        updateValueFirstName(event) {
            this.row.first_name = event.target.value;
        },
        updateValueLastName(event) {
            this.row.last_name = event.target.value;
        },
        updateValueGender(event) {
            this.row.gender = event.target.value;
        },
        updateValueDateOfBirth(event) {
            this.row.date_of_birth = event.target.value;
        },
        getProfile() {  
            this.isLoading = true;
            store.dispatch('getUser')
            .then((res) => {
                this.data.push(res.data.userDetails);
                console.log("user",res.data.userDetails )
                this.formData();
            })
            .catch(error => {
                console.error( error);
            });
        },
        cancel() {
            this.isDisabled = !this.isDisabled;
            this.btn1Disabled = true;
        },
        updateCustomer() { 
            this.isDisabled = !this.isDisabled;
            this.btn1Disabled = true;
            this.isLoading = true;
            store.dispatch('updateCustomer', {
                first_name: this.row.first_name,
                last_name: this.row.last_name,
                gender: this.row.gender,
                date_of_birth: this.row.date_of_birth,
            })
            .then((res) => {
                store.state.name = this.row.first_name;
                this.toast.success(res.data.messageForUser)
            })
            .catch(error => {
                console.error('Error fetching getWishlist:', error);
            }); 
        },
        edit1() { 
            this.isDisabled = false;
            this.btn1Disabled = false;
        },
        toggleDisabled() {
            this.isDisabled = !this.isDisabled;
        },
        toggleDisabled2() {
            this.isDisabled2 = !this.isDisabled2;
        },
        btn1Disabled() {
            this.showBtn1 = !this.showBtn1;
        },
        formData(){
            this.row.first_name = this.data[0].first_name;
            this.row.last_name = this.data[0].last_name;
            this.row.gender = this.data[0].gender;
            this.row.date_of_birth = this.data[0].date_of_birth;
        }
    },
}
</script>

<style scoped>
    .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    }

    .modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    position: relative;
    }

    .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: transparent;
    font-size: 18px;
    cursor: pointer;
    }
</style>