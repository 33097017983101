<template>
    <div class="wrapper-main-mb">
        <!-- Start Breadcrumb Area  -->
        <div class="axil-breadcrumb-area">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-12 col-md-8">
                        <div class="inner">
                            <ul class="axil-breadcrumb">
                                <li class="axil-breadcrumb-item"><router-link :to="{ name: 'Orders' }">MIS
                                        PEDIDOS</router-link>
                                </li>
                                <li class="separator"></li>
                                <li class="axil-breadcrumb-item active" aria-current="page">#{{ id }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--div class="tab-pane" id="nav-orders" role="tabpanel" style="padding: 20px 20px;">
            <div class="container pb-5 mb-sm-4">
                <div class="steps">
                    <div class="steps-header">
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" style="width: 40%" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                    <div class="steps-body">
                        <div id="ordered" class="step">
                                <span class="step-icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg></span>Ordenado</div>
                        <div id="payConfirm" class="step">
                            <span class="step-icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-settings"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg></span>Por confirmar pago</div>
                        <div id="payFinish" class="step">
                            <span class="step-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-award">
                                    <circle cx="12" cy="8" r="7"></circle>
                                    <polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline>
                                </svg></span>Proceso de pago terminado</div>
                        <div id="sent" class="step"><span class="step-icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-truck"><rect x="1" y="3" width="15" height="13"></rect><polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon><circle cx="5.5" cy="18.5" r="2.5"></circle><circle cx="18.5" cy="18.5" r="2.5"></circle></svg></span>Enviado</div>
                        <div id="delivered" class="step"><span class="step-icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg></span>Entregado</div>
                        <div id="canceled" class="step"><span class="step-icon"><i class="flaticon-close" style="font-size: 18px;"></i></span>Cancelado</div>
                        <div id="refunded" class="step"><span class="step-icon"><i class="flaticon-reply" style="font-size: 26px;"></i></span>Reembolsado</div>
                    </div>
                </div>

            </div-->
            <div class="axil-dashboard-order" style="padding: 20px;">
                <div class="product-table-heading">
                    <h1 class="title">Detalles del pedido.</h1>
                </div>
                <div v-if="isLoading">
                    <PlaceHolder></PlaceHolder>
                </div>
                <div v-for="(order) in orders" :key="order.id">
                    <div class="row">
                        <div class="col-6">{{ $filters.inDate(order.created_at) }}</div>
                        <div class="col-6 order-status"><b>{{ id }}</b></div>
                    </div>
                    <div id="stepper" class="flex flex-grow w-full justify-center items-center"><!-- stepper -->
                        <ul class="steps steps-vertical lg:steps-horizontal">
                            <li :class="['step', stepA ?'step-primary' : '']"><label for="" class="step-label">En confirmación</label></li>
                            <li :class="['step', stepC ?'step-primary' : '']"><label for="" class="step-label">Enviado</label></li>
                            <li :class="['step', stepD ?'step-primary' : '']"><label for="" class="step-label">Proceso de entrega</label></li>
                            <li :class="['step', stepE ?'step-primary' : '']"><label for="" class="step-label">Entregado</label></li>
                        </ul>
                    </div>
                </div>
                <br>
                <div>
                    <b-tabs content-class="mt-3">
                        <b-tab title="Productos" active>
                            <div v-for="(order) in orders" :key="order.id">
                                <div class="child" v-for="(product, prod) in order.payment_attempt.products_json" :key="prod">
                                    <div class="left">
                                        <div class="icon-profile">
                                            <img :src="product.image.small_image_url">
                                        </div>
                                        <div class="info" style="width: 70%;">
                                            <h5 class="name name-order">{{ product.name }}</h5>
                                            <h5 class="name name-order">{{ product.qty }}</h5>
                                            <h5 class="name name-order" style="color:#6396cb">
                                                {{ $filters.money(product.price) }}
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-tab>
                        <b-tab title="Resumen">
                            <div class="card-order c1 color-order">
                                <div v-for="(order) in orders" :key="order.id">
                                    <h3 class="data"><b>Método de pago</b></h3>
                                    <h3 class="data">Tarjeta de crédito/débito</h3>
                                    <h3 class="data">{{ order.multi_pay.pan }}</h3>
                                    <h3 class="data">Autorización #{{ order.multi_pay.authorization }}</h3>
                                    <h3 class="data">{{ $filters.inDate(order.multi_pay.date) }}</h3><br>
                                    <div class="row">
                                        <div class="col-7">
                                            <h3 class="data">Productos({{ order.items.length }})</h3>
                                        </div>
                                        <div class="col-5">
                                            <h5 class="data" style="text-align: right;">
                                                {{ $filters.money(order.sub_total) }}
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-7">
                                            <h3 class="data">Envío</h3>
                                        </div>
                                        <div class="col-5">
                                            <h5 class="data" style="text-align: right;">
                                                {{ $filters.money(order.shipping_amount) }}
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-7">
                                            <h3 class="data"><b>Total</b></h3>
                                        </div>
                                        <div class="col-5">
                                            <h5 class="data" style="text-align: right;">
                                                <b>{{ $filters.money(order.grand_total) }}</b>
                                            </h5>
                                        </div>
                                    </div>   
                                </div>
                            </div>
                        </b-tab>
                        <b-tab title="Envío">
                            <div v-for="(order) in orders" :key="order.id">
                                <div class="row">
                                    <div class="col-2" style="font-size: 45px;">
                                        ⛟
                                    </div>
                                    <div class="col-10">
                                        <span>
                                            <h2><b>{{ order.payment_attempt.shipping_address.first_name }} {{ order.payment_attempt.shipping_address.last_name }}</b></h2>
                                            <h2>{{ order.payment_attempt.shipping_address.address1 }}</h2>
                                            <h2>{{ order.payment_attempt.shipping_address.address2 }}, <br> {{ order.payment_attempt.shipping_address.postcode }} </h2>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
            
        </div>
</template>
<script>
import { useStore } from "vuex";
import { computed } from "vue";
import store from "@/store";
import { useRouter } from "vue-router";
import PlaceHolder from '@/components/PlaceHolder/OrderDetail.vue'
import { mapState } from "vuex";

export default {
    data: () => ({
        store: useStore(),
        router: useRouter(),
        user: {},
        originalOrders: [],
        orders: '',
        data: [],
        id: 0,
        stepA: 0,
        stepB: 0,
        stepC: 0,
        stepD: 0,
        stepE: 0,
        specialStep: 0
    }),
    components: {
        PlaceHolder
    },
    props: ['ID'],
    created() {
        if (store.state.user.token) {
            this.getUserData()
        } else {
            this.user = computed(() => '');
            if (store.state.user.tokenFresh) {
                store.dispatch("refreshToken")
                    .then((res) => {
                        console.log(res);
                    }).catch((err) => {
                        console.log(err);
                    });
            }
        }
    },
    computed: {
        ...mapState(['isLoading']),
        statusOrder(){
            return 'step-completed'
        }
    },
    methods: {
        async getUserData() {
            store.dispatch("getOrders").then((res) => {
                this.id = this.$route.params.id
                this.originalOrders = res.data.payload;
                this.orders = this.dataOrder();
            })
            .catch((err) => {
                console.log(err)
            })
            /*await store.dispatch("getUser").then(() => {
                this.originalOrders = this.store.state.user.data.orders;
                this.id = this.$route.params.id
                this.orders = this.dataOrder();
            })
            .catch((err) => {
                console.log(err)
            }).finally(() => {
                let ordered = 0;
                let invoiced = 0;
                let shipped = 0;
                let refunded = 0;
                let canceled = 0;
                console.log(this.orders);
                
                if(this.orders.items){
                    ordered = this.orders.items.filter( (st) => st.ordered > 0);
                    invoiced = this.orders.items.filter( (st) => st.qty_invoiced > 0);
                    shipped = this.orders.items.filter( (st) => st.qty_shipped > 0);
                    refunded = this.orders.items.filter( (st) => st.qty_refunded > 0);
                    canceled = this.orders.items.filter( (st) => st.qty_canceled > 0);
                }
                let orderedDiv = document.getElementById('ordered')
                let payConfirmDiv = document.getElementById('payConfirm')
                let payFinishDiv = document.getElementById('payFinish')
                let sentDiv = document.getElementById('sent')
                let deliveredDiv = document.getElementById('delivered')
                let canceledDiv = document.getElementById('canceled')
                let refundedDiv = document.getElementById('refunded')
                console.log(this.orders[0]);
                
                if(this.orders[0].multipay.status == 'pending'){
                    orderedDiv.classList.add("step-completed");
                }
                if (refunded.length > 0) {
                    refundedDiv.classList.add("step-completed");
                }
                if (canceled.length > 0) {
                    canceledDiv.classList.add("step-completed");
                }
                if (shipped.length > 0) {
                    sentDiv.classList.add("step-completed");
                }

                if(this.orders[0].multipay.status == 'paid'){
                    orderedDiv.classList.add("step-completed");
                    payConfirmDiv.classList.add("step-completed");
                    payFinishDiv.classList.add("step-completed");
                }
                if(this.orders[0].multipay.status == 'completed'){
                    orderedDiv.classList.add("step-completed");
                    payConfirmDiv.classList.add("step-completed");
                    payFinishDiv.classList.add("step-completed");
                    sentDiv.classList.add("step-completed");
                    deliveredDiv.classList.add("step-completed");
                }
                if(this.orders[0].multipay.status == 'closed'){
                    orderedDiv.classList.add("step-completed");
                    payConfirmDiv.classList.add("step-completed");
                    payFinishDiv.classList.add("step-completed");
                    canceledDiv.classList.add("step-completed");
                }
            })*/
        },
        dataOrder() {
            let orders = [];
            this.orders = this.originalOrders
            this.orders.filter(order => {
                if (this.id === order.payment_attempt.order) {
                    orders.push(order);
                    this.stepA = computed(() => {
                    return order.global_status.id > 0;
                    });
                    this.stepB = computed(() => {
                    return order.global_status.id > 2;
                    });
                    this.stepC = computed(() => {
                    return order.global_status.id > 3;
                    });
                    this.stepD = computed(() => {
                    return order.global_status.id > 4 && !this.specialStep.value;
                    });
                    this.stepE = computed(() => {
                    return order.global_status.id == 9;
                    });
                    this.specialStep = computed(() => {
                        return (order.global_status.id === 5 || order.global_status.id === 6 || order.global_status.id === 7);
                    });
                }
            });
            return orders
        },
    }
}
</script>