<template>
     <b-button style="background-color: #e72043;border: none;" :disabled="product.inventory.qty === 0" class="axil-btn btn btn-danger text-white">
          <slot></slot>
     </b-button>
</template>
<script>
     export default {
          props: {
               product: {
                    required: true
               }
          }
     }
</script>