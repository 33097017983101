<template>
    <nav class="navbar-search">
        <div class="navbar-brand" style="width: 100%;">
            <router-link :to="{ name: 'Index' }">
                <img src="@/assets/images/logo/logo_blanco.png"
                alt="Product Images" style="width: 40px;">
            </router-link><br>
            <div class="row" style="display: flex;align-items: center;flex-wrap: nowrap;">
                <div class="col-11" style="flex: 1;">
                    <input @input="onInput" v-model="form.searchTerm" type="search" placeholder="Buscar en Mainbit.shop" id="Buscador" class="text-white text-large" /> 
                    <div v-if="searchResults.length > 0" class="results col-10">
                        <ul>
                            <li v-for="(result, index) in searchResults" :key="index">
                                <router-link :to="{ name: 'Show', params: { 'id': result.url_key } }" @click="closeResult()">
                                    {{ result.name }}
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'Search Results' }" @click="closeResult()" class="text-info">
                                    Ver más resultados ...
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-1" style="flex: 0 0 auto;">
                    <div id="cart-dropdown-btn" @click.prevent="openSideCartBar" class="cart-dropdown-btn cart-menu-search" v-if="!isLogged">
                        <span class="cart-count" v-if="cart && cart.items_qty > 0">{{ cart.items_qty }}</span>
                        <img src="@/assets/images/logo/carrito-rojo.png" alt="Product Images" style="margin-top: -5px">
                    </div>
                    <div id="cart-dropdown-btn" @click.prevent="openSideCartBar" class="cart-dropdown-btn cart-menu-search" v-if="isLogged">
                        <span class="cart-count" v-if="productsCartTemp.length > 0">{{ total }}</span>
                        <img src="@/assets/images/logo/carrito-rojo.png" alt="Product Images" style="margin-top: -5px">
                    </div>
                </div>
            </div>
            <label for="" class="text-white text-large text-nav" v-if="name">Hola, {{ name }}!</label><br>
        </div>
    </nav>
    <div class="content" v-on="totalTemp()">
        <header>
        </header>
    </div>
    <nav class="navbar-e">
        <router-link :to="{ name: 'Index' }" class="custom-link">
            <div class="row">
                <i class="far fa-home"></i><br>
                <label for="">Inicio</label>
            </div>
        </router-link>
        <router-link :to="{ name: 'WishList' }" class="custom-link">
            <div class="row">
                <i class="far fa-heart"></i><br>
                <label for="">Favoritos</label>
            </div>
        </router-link>
        <router-link :to="{ name: 'Profile' }" class="custom-link">
            <div class="row">
                <i class="far fa-user"></i><br>
                <label for="">Cuenta</label>
            </div>
        </router-link>
        <a style="width: 15%;">
            <div class="row menu-span">
                <nav class="menu--bottom--right" role="navigation" style="position: absolute">
                    <div class="menuToggle" style="position: absolute">
                        <input type="checkbox" v-model="isMenuOpen.true"/>
                        <span></span>
                        <span></span>
                        <span></span>
                        <ul class="text-left text-black ul-color-text menuItem" v-if="isMenuOpen">
                            <div class="modal" id="modal-session">
                                <a href="#" class="modal-bg"></a>
                                <div class="modal-content">
                                    <a href="#" class="modal-exit"><i class="fas fa-times"></i></a>
                                    <h3 class="modal-title axil-breadcrumb-item">¿Estás seguro que quieres salir de la sesión?</h3>
                                    <div class="modal-text">

                                        <div class="row">
                                            <b-spinner v-if="isLoading" variant="info" class="position-loader"
                                                label="Loading..."></b-spinner>
                                            <div class="col-6" v-else>
                                                <b-button variant="light">
                                                    <a class="cancel-modal" href="#">Cancelar</a>
                                                </b-button>
                                            </div>
                                            <div class="col-6">
                                                <b-button variant="success" @click="handleLogOut">Aceptar</b-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="scrollable-div-ul">
                                <div class="row" v-if="!isLogged" style="margin-top: 5%;">
                                    <div class="col-4 text-center" v-if="!userImg">
                                        <img  class="img-circle" src="https://rc1.mainbitpreproduccion.shop/vendor/mainbit/footer/icons/logo_footer.png" alt="Profile">
                                    </div>
                                    <div class="col-8 text-right text-very-large">
                                        Hola, {{ name }} !
                                    </div>
                                    <li v-if="!isLogged">
                                        <a href="#modal-session" class="text-very-large text-danger" style="padding: 0px 30px 0px;">
                                            Cerrar sesión</a>
                                    </li>
                                </div>
                                <div v-else class="back-sig-in">
                                    <li v-if="isLogged" class="text-li-sign-in">
                                        <router-link :to="{ name: 'Sign In' }" class="text-white" @click="closeMenu">
                                            <label for="" style="margin-top: 10px;">Identificarse</label>
                                        </router-link>
                                    </li>
                                </div>
                                <div v-if="Categories.length === 0" class="overlay">
                                    <div class="spinner"></div>
                                </div>
                                <li v-if="!isLogged" style="margin-top: 8%;">
                                    <router-link :to="{ name: 'Profile' }" @click="closeMenu">
                                        <div class="col-lg-6 div-li"> 
                                            <div class="row margin-div">
                                                <div class="col-2 margin-icon">
                                                    <i class="far fa-user li-icon"></i>
                                                </div>
                                                <div class="col-10 text-left text-li-menu">
                                                    <b><h3 class="text-very-large">Información personal</h3></b>
                                                </div>
                                            </div>
                                        </div>
                                    </router-link>
                                    <hr>
                                </li>
                                <li v-if="!isLogged">
                                    <router-link :to="{ name: 'Orders' }" class="router-link" @click="closeMenu">
                                        <div class="col-lg-6 div-li"> 
                                            <div class="row margin-div">
                                                <div class="col-2 margin-icon">
                                                    <i class="far fa-archive li-icon"></i>
                                                </div>
                                                <div class="col-10 text-left text-li-menu">
                                                    <b><h3 class="text-very-large">Pedidos</h3></b>
                                                </div>
                                            </div>
                                        </div>
                                    </router-link>
                                    <hr>
                                </li>
                                <li v-if="!isLogged">
                                    <router-link :to="{ name: 'WishList' }" class="router-link" @click="closeMenu">
                                        <div class="col-lg-6 div-li"> 
                                            <div class="row margin-div">
                                                <div class="col-2 margin-icon">
                                                    <i class="far fa-heart li-icon"></i>
                                                </div>
                                                <div class="col-10 text-left text-li-menu">
                                                    <b><h3 class="text-very-large">Wishlist</h3></b>
                                                </div>
                                            </div>
                                        </div>
                                    </router-link>
                                    <hr>
                                </li>
                                <li v-if="!isLogged">
                                    <router-link :to="{ name: 'My Reviews' }" class="router-link" @click="closeMenu">
                                        <div class="col-lg-6 div-li"> 
                                            <div class="row margin-div">
                                                <div class="col-2 margin-icon">
                                                    <i class="far fa-comment li-icon"></i>
                                                </div>
                                                <div class="col-10 text-left text-li-menu">
                                                    <b><h3 class="text-very-large">Reseñas</h3></b>
                                                </div>
                                            </div>
                                        </div>
                                    </router-link>
                                    <hr>
                                </li>
                                <li v-if="!isLogged">
                                    <router-link :to="{ name: 'Addresses' }" class="router-link" @click="closeMenu">
                                        <div class="col-lg-6 div-li"> 
                                            <div class="row margin-div">
                                                <div class="col-2 margin-icon">
                                                    <i class="far fa-map-marked-alt li-icon"></i>
                                                </div>
                                                <div class="col-10 text-left text-li-menu">
                                                    <b><h3 class="text-very-large">Direcciones</h3></b>
                                                </div>
                                            </div>
                                        </div>
                                    </router-link>
                                    <hr>
                                </li>
                                <li>
                                    <router-link :to="{ name: 'Addresses' }" class="router-link" @click="closeMenu">
                                        <div class="col-lg-6 div-li"> 
                                            <div class="row margin-div">
                                                <div class="col-2 margin-icon">
                                                    <i class="far fa-percent li-icon"></i>
                                                </div>
                                                <div class="col-10 text-left text-li-menu">
                                                    <b><h3 class="text-very-large">Ofertas</h3></b>
                                                </div>
                                            </div>
                                        </div>
                                    </router-link>
                                    <hr>
                                </li>
                                <li>
                                    <div class="col-lg-6 div-li"> 
                                        <div class="row margin-div" style="margin-top: -13% !important;">
                                            <div class="col-2 margin-icon">
                                                <i class="far fa-bars li-icon" style="margin-top: 20px;"></i>
                                            </div>
                                            <div class="col-10 text-left text-li-menu">
                                                <div class="collapse-container">
                                                    <input type="checkbox" id="collapse-menu" class="collapse-toggle">
                                                    <label for="collapse-menu" class="collapse-header">
                                                        <b><h3 style="text-align: left;color: #518ce5;margin-left: -30px;margin-top: 10px" class="text-very-large">Categorias</h3></b>
                                                    </label>
                                                    <div class="collapse-content">
                                                        <div class="scrollable-div">
                                                        <div v-for="(category, index) in Categories" :key="index">
                                                            <input type="checkbox" :id="'collapse2' + index" class="collapse-toggle">
                                                            <label style="margin-left: -30px;" :for="'collapse2' + index" class="collapse-header text-very-large">{{ category.translations.name }}</label>
                                                            <div class="collapse-content-2">
                                                                <div v-for="(subCategory, ix) in SubCategories" :key="ix" class="menu-item">
                                                                    <router-link
                                                                        @click="closeMenu"
                                                                        :to="{ name: 'Category', params: { 'slug': subCategory.translations.slug } }"
                                                                        v-show="category.id === subCategory.parent_id">
                                                                        <h3 class="text-very-large">{{ subCategory.translations.name }}</h3>
                                                                    </router-link>
                                                                </div>
                                                            </div>
                                                        </div></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                </li>
                                <li v-if="!isLogged">
                                    <router-link :to="{ name: 'Addresses' }" class="router-link" @click="closeMenu">
                                        <div class="col-lg-6 div-li"> 
                                            <div class="row margin-div">
                                                <div class="col-2 margin-icon">
                                                    <i class="far fa-bell li-icon"></i>
                                                </div>
                                                <div class="col-10 text-left text-li-menu">
                                                    <b><h3 class="text-very-large">Notificaciones</h3></b>
                                                </div>
                                            </div>
                                        </div>
                                    </router-link>
                                    <hr>
                                </li>
                                <li>
                                    <a @click.prevent="openSearch" @click="closeMenu">
                                        <div class="col-lg-6 div-li"> 
                                            <div class="row margin-div">
                                                <div class="col-2 margin-icon">
                                                    <i class="far fa-search li-icon"></i>
                                                </div>
                                                <div class="col-10 text-left text-li-menu">
                                                    <b><h3 class="text-very-large">Buscar</h3></b>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <hr>
                                </li>
                                <li>
                                    <router-link :to="{ name: 'Index' }" class="router-link" @click="closeMenu">
                                        <div class="col-lg-6 div-li"> 
                                            <div class="row margin-div">
                                                <div class="col-2 margin-icon">
                                                    <i class="far fa-home li-icon"></i>
                                                </div>
                                                <div class="col-10 text-left text-li-menu">
                                                    <b><h3 class="text-very-large">Inicio</h3></b>
                                                </div>
                                            </div>
                                        </div>
                                    </router-link>
                                    <hr>
                                </li>
                                <li v-if="!isLogged">
                                    <router-link :to="{ name: 'Tickets' }" class="router-link" @click="closeMenu">
                                        <div class="col-lg-6 div-li"> 
                                            <div class="row margin-div">
                                                <div class="col-2 margin-icon">
                                                    <i class="far fa-question li-icon"></i>
                                                </div>
                                                <div class="col-10 text-left text-li-menu">
                                                    <b><h3 class="text-very-large">Tickets</h3></b>
                                                </div>
                                            </div>
                                        </div>
                                    </router-link>
                                    <hr>
                                </li>
                            </div>
                        </ul>
                    </div>
                </nav>
            </div>
        </a>
    </nav>
    <CartDropdown :openDialogCart="showDialogCart" @changeDialogCart="openSideCartBar" :cart="cart" />
    <SearchDropdown :openSearch="showSearch" @changeSearch="openSearch" />
    <ProfileDropdown :openProfile="showProfile" @changeProfile="openProfile" />
    <!-- End Mainmenu Area -->
</template>

<script>
import '@/assets/css/style.css';
import CartDropdown from "./CartDropdown.vue"
import SearchDropdown from "./SearchDropdown.vue"
import ProfileDropdown from "./SearchDropdown.vue"
import store from '@/store/index.js'
//import router from "@/router";
import { mapState } from 'vuex'
import { useLogto } from '@logto/vue';
import { useAuth } from '@/composables/useAuth';
import { debounce } from 'lodash';

export default {
    name: 'NavBar',
    props: {
        Categories: {
            required: true,
            type: Array
        },
        user: {
            required: false
        },
        cart: {
            required: false
        },
        SubCategories: {
            required: true,
            type: Array
        },
        Error: {
            required: true
        },
    },
    components: {
        CartDropdown,
        SearchDropdown,
        ProfileDropdown
        // Slide,
    },
    data: () => ({
        isVisible: false,
        currentUser: useAuth(),
        showNavMenu: false,
        showMask: false,
        showDialogCart: false,
        showSearch: false,
        showProfile: false,
        showDropDown: false,
        welcome: " ",
        total: 0,
        isValidToken: false,
        token: null, // Estado del token
        tokenExpired: false,
        isMenuOpen: {
            true: false, // o el valor inicial que desees
            false: true
        },
        form: {
            searchTerm: '',
            start: 0,
            offset: 20
        },
        searchResults: [],
        userName: store.state.name
    }),
    computed: {
        isLogged() {
            if (!store.state.user.token) {
                return true;
            }
            return false;
        },
        ...mapState(['isLoading', 'productsCartTemp', 'productsTemp','name', 'querySearch'])
    },
    mounted() {
        this.token = store.state.user.token; 
        this.tokenExpired = store.state.user.tokenExpired
        if (store.state.user.token) {
            this.getProfile();
        }
        if(this.Categories.length > 0){
            this.isLoading = false
        }else{
            this.isLoading = true
        }
        document.addEventListener('click', this.handleClickOutside);
        document.addEventListener('click', this.handleClickOutsideSearch);
    },
    created() {
        this.logto = useLogto();
    },
    methods: {
        handleClickOutsideSearch(event) {
        // Si el clic es fuera del div
            if (!this.$el.contains(event.target)) {
                this.isVisible = true;
                if(this.form.searchTerm.length > 0 && this.isVisible)
                {
                    this.searchResults.splice(0, this.searchResults.length);
                    this.form.searchTerm = '';
                }
            }
        },
        toggleMenu() {
            this.isMenuOpen.false = !this.isMenuOpen.false; // Alterna el estado del menú
        },
        closeMenu() {
            this.isMenuOpen.true = !this.isMenuOpen.true; // Cierra el menú
        },
        handleClickOutside(event) {
            const menu = this.$refs.menu;
            if (menu && !menu.contains(event.target)) {
                this.closeMenu();
            }
        },
        checkLogin(){
            if(this.tokenExpired && store.state.user.token){
                const accessToken = this.logto.getAccessToken('https://api.mainbitpreproduccion.shop');
                if (!accessToken.success) {
                    return this.handleLogOut();
                }
                sessionStorage.setItem('TOKEN', accessToken);
                store.state.user.token = accessToken;
                store.state.email = this.userDataFromLogTo.email;
                store.dispatch('userProfile', {
                    accessToken: accessToken, 
                    email: this.userDataFromLogTo.email
                })
            }
        },  
        handleLogin() {
            const exampleToken = store.state.user.token; 
            this.token = exampleToken;
        },
        handleLogOut() {
            this.logto.clearAccessToken();
            this.logto.clearAllTokens();
            store.state.tokenExpired = true;
            sessionStorage.removeItem('TOKEN');
            sessionStorage.removeItem('REFRESH_TOKEN');
            //this.logto.signOut('http://localhost:8080/');
            this.logto.signOut('https://mobile.mainbitpreproduccion.shop/');
        },
        async handleRegister() {
            const redirectUri = 'https://mobile.mainbitpreproduccion.shop/callback';
            try {
                const result = await this.logto.signIn(redirectUri, { interactionMode: 'signUp' });
                console.log('Sign in result:', result);
                window.location.href = 'https://auth.mainbitpreproduccion.shop/register';
            } catch (error) {
                console.error('Error during sign up:', error);
                // Puedes agregar más lógica para manejar el error aquí
            }
        },
        totalTemp() {
            if (this.isLogged && this.productsCartTemp.length > 0) {
                this.total = this.productsCartTemp.reduce((a, b) => a + parseInt(b.quantity), 0);
                return this.total
            }
        },
        openNavMobileMenu() {
            this.showNavMenu = !this.showNavMenu
            this.showMask = !this.showMask
        },
        addClassForMainMenu() {
            let itemRef = this.$refs['itemMenu-1']
            let subRef = this.$refs['subMenu-1']
            // this.$ref['subMenu-1'].style
            if (subRef.style.display == "") {
                itemRef.classList.value = 'menu-item-has-children open'
                subRef.style.display = "block"
            } else {
                subRef.style.display = ""
                itemRef.classList.value = 'menu-item-has-children'
            }
        },
        openSideCartBar() {
            this.showDialogCart = !this.showDialogCart
            this.showMask = !this.showMask
        },
        openSearch() {
            this.showSearch = !this.showSearch
            this.showMask = !this.showMask
        },
        openProfile() {
            this.showProfile = !this.showProfile
        },
        getProfile(){
            store.dispatch('getUser');
        },
        redirectToRegister() {
            this.logto.clearAccessToken();
            this.logto.clearAllTokens();
            try {
                // URL de registro de Logto
                const registerUrl = this.$logtoEndpoint + 'register';
                
                // Redirige al usuario a la URL de registro
                window.location.href = registerUrl;
            } catch (error) {
                // Manejo de errores
                this.errorMessage = error.message || 'An error occurred during redirection.';
                console.error('Error during redirection:', error.message || error);
            }
        },
        searchProducts() {
            if(this.form.searchTerm.length > 0){
                this.isLoading = true;
                store.dispatch("search", this.form)
                .then((response) => {
                    response.data.payload.hits.forEach((result) => {
                        this.searchResults.push(result);
                        this.querySearch.push(result);
                    })
                    console.log("resultados de la busqueda: ", this.searchResults);
                    this.searchResults = this.searchResults.slice(this.searchResults.length - 6);
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                });
            }else{
                this.searchResults.splice(0, this.searchResults.length);
                this.form.searchTerm = '';
            }
        },

        //Busqueda en tiempo real
        handleInputChange(value) {
            console.log(value);
        },

        // Crear una función debounce
        debouncedHandleInputChange: debounce(function (value) {
            this.handleInputChange(value);
            this.searchProducts();
        }, 300),

        onInput() {
            // Llamar a la función debounce
            this.debouncedHandleInputChange(this.form.searchTerm);
        },
        closeResult(){
            this.searchResults.splice(0, this.searchResults.length);
            this.form.searchTerm = '';
        }
    },
};
</script>
